body {
  color: #f8fafc;
}

.Carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  text-align: center;
}

header h1 {
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

@media (min-width: 768px) {
  header h1 {
    font-size: 3rem;
  }
}

header p {
  color: #94a3b8;
}

.tag-list {
  width: 50rem;
  max-width: 90vw;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  gap: 1rem 0;
  position: relative;
  padding: 1.5rem 0;
  overflow: hidden;
}

.loop-slider .inner {
  display: flex;
  width: fit-content;
  animation-name: loop;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: var(--direction);
  animation-duration: var(--duration);
}

.tag {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  color: #e2e8f0;
  font-size: 0.9rem;
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity)); 
  border-radius: 0.4rem;
  padding: 2.1rem 3rem;
  margin-right: 1rem;
  box-shadow: 
    0 0.1rem 0.2rem rgb(0 0 0 / 20%),
    0 0.1rem 0.5rem rgb(0 0 0 / 30%),
    0 0.2rem 1.5rem rgb(0 0 0 / 40%);
}

.tag img {
  width: 48px; 
  height: 48px; 
  object-fit: contain; 
}

.tag span {
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
}

.fade {
  pointer-events: none;
  background: linear-gradient(10deg, #7f7f7f, transparent 50%, transparent 50%, #96aacb);
  position: absolute;
  border-width: 3px;
  border-radius: 15px;
  border-color: white;
  inset: 0;
}

@keyframes loop {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
